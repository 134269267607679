import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

// Create the context with a different name
export const PerformerAWContext = createContext();

// Add 'export' keyword here
export const filterPerformersAW = (performers, filters = {}) => {

  if (!Array.isArray(performers)) return [];
  
  const safeFilters = {
    ethnicity: [],
    age: [],
    fetishes: [],
    build: [],
    searchTerm: '',
    ...filters
  };

  return performers.filter(performer => {
    const hasActiveFilters = Object.values(safeFilters).some(arr => 
      Array.isArray(arr) ? arr.length > 0 : false
    );
    const hasSearchTerm = safeFilters.searchTerm?.trim().length > 0;
    
    if (!hasActiveFilters && !hasSearchTerm) {
      return true;
    }

    // Search term filter
    if (hasSearchTerm) {
      const searchTerm = safeFilters.searchTerm.toLowerCase();
      const searchableFields = [
        performer.displayName,
        // performer.ethnicity,
        performer.age.toString(),
        ...(performer.details?.willingnesses || [])
      ];

      const matchesSearch = searchableFields
        .filter(field => field)
        .some(field => field.toString().toLowerCase().includes(searchTerm));

      if (!matchesSearch) return false;
    }

    // Filter checks
    const checks = {
      ethnicity: !safeFilters.ethnicity?.length || 
        safeFilters.ethnicity.some(ethnicity => 
          performer.ethnicity?.toLowerCase() === ethnicity.toLowerCase()
        ),

      age: !safeFilters.age?.length || 
        safeFilters.age.some(ageRange => {
          // Get age from persons array
          const age = parseInt(performer.age);
          if (!age) return false;
          
          console.log('Checking age:', { age, ageRange }); // Debug log
          
          switch(ageRange) {
            case "Teen 18+": return age >= 18 && age <= 19;
            case "20s": return age >= 20 && age <= 29;
            case "30s": return age >= 30 && age <= 39;
            case "40s": return age >= 40 && age <= 49;
            case "Mature 50+": return age >= 50;
            default: return false;
          }
        }),

      fetishes: !safeFilters.fetishes?.length || 
        safeFilters.fetishes.some(fetish => 
          performer.details?.willingnesses?.some(w => 
            w?.toLowerCase() === fetish.toLowerCase()
          )
        ),
    };

    return Object.values(checks).every(result => result);
  });
};

// Create the provider component with a different name
export const PerformerAWProvider = ({ children }) => {
  const [performers, setPerformers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({
    ethnicity: [],
    age: [],
    fetishes: [],
    build: [],
    searchTerm: ''
  });

  // Add handleFilterChange function
  const handleFilterChange = (newFilters) => {
    console.log('AW received new filters:', newFilters); // Debug log
    setSelectedFilters(newFilters);
  };

  useEffect(() => {
    const fetchPerformers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/performersAW`);
        console.log('response', response);
        const performersArray = response.data.data.models;
        if (!performersArray || performersArray.length === 0) {
          console.warn('No performers data received from API');
          setPerformers([]);
          setLoading(false);
          return;
        }

        const performersData = performersArray.map((performer) => ({
          uniqueModelId: performer.uniqueModelId,
          performerId: performer.performerId,
          displayName: performer.displayName,
          details: {
            ...performer.details,
            willingnesses: performer.details?.willingnesses || [],
            description: performer.details?.description || ''
          },
          chatRoomUrl: performer.chatRoomUrl,
          profilePictureUrl: performer.profilePictureUrl.size320x180,
          profilePictureFullUrl: performer.profilePictureUrl.size320x240,
          ethnicity: performer.ethnicity || 'N/A',
          age: performer.persons?.[0]?.age || 'N/A',
          category: performer.category || '',
          fetishes: performer.details?.willingnesses || [],
          build: performer.details?.bodyType || performer.category || '',
          livePreRollUrl: performer.livePreRollUrl
        }));

        setPerformers(performersData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching performers:", error);
        setLoading(false);
      }
    };

    fetchPerformers();
  }, []);

  // Add this effect to filter performers when filters change
  const filteredPerformers = filterPerformersAW(performers, selectedFilters);

  return (
    <PerformerAWContext.Provider value={{ 
      performers: filteredPerformers, // Return filtered performers instead of all performers
      loading, 
      selectedFilters,
      handleFilterChange 
    }}>
      {children}
    </PerformerAWContext.Provider>
  );
};
