import React, { useContext, useState } from "react";
import { Box, Typography, Avatar, Button } from "@mui/material";
import { styled, keyframes } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { PerformerContext, filterPerformers } from "../contexts/PerformerContext";
import { PerformerAWContext, filterPerformersAW } from "../contexts/PerformerAWContext";
import ChatBot from './ChatBot';
import RightSidebar from './RightSidebar';

// Add blinking animation
const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const LiveDot = styled('span')({
  height: '8px',
  width: '8px',
  backgroundColor: '#4CAF50',
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: '8px',
  animation: `${blink} 1.5s ease-in-out infinite`,
});

const LiveText = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  color: '#4CAF50',
  fontWeight: 'bold',
});

const ChatButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF6B6B, #FF8E53, #FF2BAA)',
  borderRadius: '50px',
  color: 'white',
  padding: '8px 24px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  minWidth: 'fit-content',
  marginLeft: '16px',
  whiteSpace: 'nowrap',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF5252, #FF7043, #FF1A8C)',
    '& .heart-icon': {
      transform: 'scale(1.2)',
    },
  },
}));

const ButtonHeartIcon = styled(FavoriteIcon)({
  fontSize: '16px',
  marginLeft: '4px',
  transition: 'transform 0.2s ease-in-out',
});

const PerformerCard = () => {
  const { performers, loading, selectedFilters } = useContext(PerformerContext);
  const { performers: performersAW, loading: loadingAW } = useContext(PerformerAWContext);
  const [activeChatPerformer, setActiveChatPerformer] = useState(null);

  if (loading || loadingAW) {
    return <Typography>Loading performers...</Typography>;
  }

  // Filter performers using their respective filter functions
  const filteredPerformers = filterPerformers(performers, selectedFilters || {});
  const filteredPerformersAW = filterPerformersAW(performersAW, selectedFilters || {});

  // Interleave filtered performers
  const allPerformers = filteredPerformers.reduce((acc, performer, index) => {
    acc.push(performer);
    if (filteredPerformersAW[index]) {
      acc.push(filteredPerformersAW[index]);
    }
    return acc;
  }, []);

  // Add remaining filtered performers from performersAW if any
  if (filteredPerformersAW.length > filteredPerformers.length) {
    allPerformers.push(...filteredPerformersAW.slice(filteredPerformers.length));
  }

  const handleChatOpen = (performer) => {
    // Send 'chatbot' event to Google Analytics
    window.gtag('event', 'chatbot', {
      'event_category': 'engagement',
      'event_label': `Chat with ${performer.displayName}`,
      'performer_name': performer.displayName
    });

    setActiveChatPerformer(performer);
  };

  const handleChatClose = () => {
    setActiveChatPerformer(null);
  };

  return (
    <>
      {allPerformers.map((performer, index) => (
        <React.Fragment key={performer.uniqueModelId}>
          {/* Performer Card */}
          <Box
            sx={{
              maxWidth: 500,
              border: "1px solid #dbdbdb",
              borderRadius: "8px",
              overflow: "hidden",
              backgroundColor: "#fff",
              marginBottom: 3,
            }}
          >
            {/* Header */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Avatar
                src={performer.profilePictureUrl}
                alt={performer.displayName}
                sx={{ width: 40, height: 40, marginRight: 1.5 }}
              />
              <Typography sx={{ 
                fontWeight: "bold",
                textAlign: "left"
              }}>
                {performer.displayName}
              </Typography>
              <Box sx={{ flex: 1 }} />
              <LiveText>
                <LiveDot />
                <Typography variant="body2" sx={{ color: 'inherit' }}>
                  Live Now
                </Typography>
              </LiveText>
            </Box>

            {/* Main Image */}
            <Box
              sx={{
                height: 400,
                width: '100%',
                overflow: 'hidden',
                cursor: 'pointer',
              }}
              onClick={() => window.open(performer.chatRoomUrl, '_blank')}
            >
              <Box
                component="img"
                src={performer.profilePictureFullUrl}
                alt={performer.displayName}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
                onError={(e) => {
                  console.log('Failed to load image:', e.target.src);
                  e.target.style.display = 'none';
                }}
              />
            </Box>

            {/* Footer */}
            <Box sx={{ padding: "10px", width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  padding: "0 16px",
                }}
              >
                <Box sx={{ 
                  textAlign: 'left'
                }}>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    Age: {performer.age}
                  </Typography>
                  {performer.details?.willingnesses ? (
                    <Typography variant="body2" sx={{ mb: 0.5 }}>
                      Fetishes: {performer.details.willingnesses.map(fetish => `#${fetish}`).join(' ')}
                    </Typography>
                  ) : performer.fetishes && (
                    <Typography variant="body2" sx={{ mb: 0.5 }}>
                      Fetishes: {performer.fetishes.map(fetish => `#${fetish}`).join(' ')}
                    </Typography>
                  )}
                </Box>

                {/* Only show ChatButton for performers from PerformerContext */}
                {filteredPerformers.some(p => p.uniqueModelId === performer.uniqueModelId) && (
                  <ChatButton
                    variant="contained"
                    onClick={() => handleChatOpen(performer)}
                  >
                    Let's Chat! <ButtonHeartIcon className="heart-icon" />
                  </ChatButton>
                )}
              </Box>
            </Box>
          </Box>

          {/* Insert RightSidebar after every 5th card on mobile */}
          {(index + 1) % 5 === 0 && (
            <Box 
              sx={{ 
                width: '100%',
                maxWidth: 500,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'center',
                mb: 3
              }}
            >
              <RightSidebar />
            </Box>
          )}
        </React.Fragment>
      ))}

      {/* ChatBot component */}
      <ChatBot
        isOpen={activeChatPerformer !== null}
        onClose={handleChatClose}
        performerName={activeChatPerformer?.displayName}
        performer={activeChatPerformer}
        sx={{
          position: 'fixed',
          zIndex: 9999,
        }}
      />
    </>
  );
};

export default PerformerCard;
