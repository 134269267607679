import React, { useContext, useState } from "react";
import { PerformerContext } from "../contexts/PerformerContext";
import { PerformerAWContext } from "../contexts/PerformerAWContext";
// import profilePicture from "../assets/images/profilepicture.png";
import {
  Box,
  Typography,
  List,
  ListItem,
  Checkbox,
  // Avatar,
  // ListItemAvatar,
  ListItemText,
} from "@mui/material";
import logo from "../assets/images/logo512.png";
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

const LeftSidebar = () => {
  const { performers: performersMain, handleFilterChange } = useContext(PerformerContext);
  const awContext = useContext(PerformerAWContext);
  const { performers: performersAW} = awContext || { performers: [] };

  // Debug logs for contexts
  // console.log('Main Context Performers:', {
  //   count: performersMain?.length || 0,
  //   ages: performersMain?.map(p => p.age),
  //   first: performersMain?.[0]
  // });

  // console.log('AW Context Performers:', {
  //   count: performersAW?.length || 0,
  //   ages: performersAW?.map(p => p.age),
  //   first: performersAW?.[0]
  // });

 

  // Get fetishes from both contexts
  const mainFetishes = performersMain?.flatMap(performer => 
    performer?.fetishes || []
  ) || [];

  const awFetishes = performersAW?.flatMap(performer => 
    performer?.fetishes || []
  ) || [];

  // Combine and count fetishes from both sources
  const combinedFetishes = [...mainFetishes, ...awFetishes].reduce((acc, fetish) => {
    acc[fetish] = (acc[fetish] || 0) + 1;
    return acc;
  }, {});

  // Convert to sorted array of unique fetishes
  const allUniqueFetishes = Object.keys(combinedFetishes).sort();




  const categories = {
    ethnicity: [...new Set([
      ...performersMain.map(p => p.ethnicity),
      ...performersAW.map(p => p.ethnicity)
    ])].filter(Boolean).sort(),
    
    age: [
      { label: "Teen 18+", range: [18, 19] },
      { label: "20s", range: [20, 29] },
      { label: "30s", range: [30, 39] },
      { label: "40s", range: [40, 49] },
      { label: "Mature 50+", range: [50, 100] }
    ],
    
    fetishes: allUniqueFetishes,
    
    build: [...new Set([
      ...performersMain.map(p => p.build),
      ...performersAW.map(p => p.category)
    ])].filter(Boolean).sort()
  };

  const [selectedFilters, setSelectedFilters] = useState({
    ethnicity: [],
    age: [],
    fetishes: [],
    build: []
  });

  const [searchTerm, setSearchTerm] = useState("");

  const handleToggle = (category, value) => {
    setSelectedFilters(prev => {
      const newFilters = { ...prev };
      if (newFilters[category].includes(value)) {
        newFilters[category] = newFilters[category].filter(item => item !== value);
      } else {
        newFilters[category] = [...newFilters[category], value];
      }
      
      if (category === 'age') {
        const ageRanges = newFilters.age.map(ageLabel => {
          const rangeObj = categories.age.find(item => item.label === ageLabel);
          return rangeObj ? rangeObj.range : null;
        }).filter(range => range !== null);
        
        handleFilterChange({
          ...newFilters,
          ageRanges
        });
      } else {
        handleFilterChange(newFilters);
      }
      
      return newFilters;
    });
  };

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    
    // Send search event to Google Analytics with custom parameter
    if (newSearchTerm.trim()) {  // Only track non-empty searches
      window.gtag('event', 'search', {
        search_term: newSearchTerm,  // This is the custom parameter
        event_category: 'engagement',
        event_label: 'Performer Search'
      });
    }
    
    // Update search term for both contexts
    handleFilterChange({
      ...selectedFilters,
      searchTerm: newSearchTerm
    });

    if (awContext && awContext.handleFilterChange) {
      awContext.handleFilterChange({
        ...selectedFilters,
        searchTerm: newSearchTerm
      });
    }
  };

  const renderCategorySection = (title, items, category) => (
    <Box sx={{ mb: 4 }}>
      <Typography 
        variant="h6" 
        sx={{ 
          mt: 2, 
          mb: 1, 
          px: 2,
          color: '#FF4196',
          fontSize: '1.2rem',
          fontWeight: 500,
          textAlign: 'left'
        }}
      >
        {title}
      </Typography>
      <List sx={{ width: '100%', py: 0 }}>
        {items.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              py: 0.5,
              px: 2,
              borderRadius: 1,
              "&:hover": { bgcolor: "action.hover" },
              width: '100%',
              maxWidth: '100%'
            }}
          >
            {/* <ListItemAvatar sx={{ minWidth: 40 }}>
              <Avatar 
                src={profilePicture} 
                sx={{ 
                  width: 30,
                  height: 30,
                }} 
              />
            </ListItemAvatar> */}
            <ListItemText
              primary={typeof item === 'object' ? item.label : item}
              primaryTypographyProps={{ 
                fontWeight: 500,
                fontSize: "0.9rem",
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              sx={{ flex: '1 1 auto', minWidth: 0 }}
            />
            <Checkbox
              checked={selectedFilters[category].includes(typeof item === 'object' ? item.label : item)}
              onChange={() => handleToggle(category, typeof item === 'object' ? item.label : item)}
              sx={{
                p: 0.5,
                color: 'grey.400',
                '&.Mui-checked': {
                  color: '#FF4196'
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

 
  return (
    <Box sx={{ 
      width: 300,
      height: "100vh", 
      borderRight: "1px solid #dbdbdb", 
      bgcolor: "#f8f9fa", 
      overflow: "auto",
      '& .MuiTypography-root': {
        textAlign: 'left'
      },
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#FF4196',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#ff1a85',
      },
      scrollbarWidth: 'thin',
      scrollbarColor: '#FF4196 #f1f1f1'
    }}>
      <Box sx={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        p: 2,
        gap: 2
      }}>
        <img 
          src={logo} 
          alt="InstaCamGirls Logo" 
          style={{ 
            width: "45px", 
            height: "45px",
            objectFit: "contain"
          }} 
        />
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          InstaCamGirls
        </Typography>
      </Box>

      <Box sx={{ 
        px: 2, 
        mb: 2,
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#fff',
          borderRadius: '20px',
          padding: '4px 12px',
          border: '1px solid #dbdbdb',
          '&:hover': {
            borderColor: '#FF4196',
          },
          '&:focus-within': {
            borderColor: '#FF4196',
            boxShadow: '0 0 0 2px rgba(255, 65, 150, 0.1)',
          }
        }}>
          <SearchIcon sx={{ color: 'grey.500', mr: 1 }} />
          <InputBase
            placeholder="Search performers..."
            value={searchTerm}
            onChange={handleSearch}
            sx={{
              flex: 1,
              '& input': {
                padding: '6px 0',
                fontSize: '0.9rem',
              }
            }}
          />
        </Box>
      </Box>

      {renderCategorySection("Ethnicity", categories.ethnicity, "ethnicity")}
      {renderCategorySection("Age", categories.age, "age")}
      {renderCategorySection("Fetishes", categories.fetishes, "fetishes")}
      {/* {renderCategorySection("Body Type", categories.build, "build")} */}
    </Box>
  );
};

export default LeftSidebar;