import "./App.css";
import { Box, IconButton } from "@mui/material";
import Stories from "./components/Stories";
import LeftSidebar from "./components/LeftSidebar";
import PerformerCard from "./components/PerformerCard";
import RightSidebar from "./components/RightSidebar";
import { PerformerProvider } from "./contexts/PerformerContext";
import { PerformerAWProvider } from "./contexts/PerformerAWContext";
import VideoCard from "./components/VideoCard";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import Footer from "./components/Footer";
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics';

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useGoogleAnalytics();

  return (
    <PerformerProvider>
      <PerformerAWProvider>
        <div className="App">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ 
              mr: 2, 
              display: { lg: 'none' },
              position: 'fixed',
              top: 16,
              left: 16,
              zIndex: 1300,
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ 
            display: "flex", 
            minHeight: "100vh",
            position: "relative",
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', lg: 'center' }
          }}>
            <Box 
              component="nav"
              sx={{
                width: { xs: 300, lg: 300 },
                flexShrink: 0,
                position: { xs: 'fixed', lg: 'fixed' },
                height: '100vh',
                borderRight: 1,
                borderColor: "divider",
                bgcolor: "background.paper",
                left: { 
                  xs: mobileOpen ? 0 : -300,
                  lg: 0
                },
                zIndex: 1200,
                transition: 'left 0.3s ease',
                boxShadow: { xs: mobileOpen ? 2 : 0, lg: 0 },
              }}
            >
              <LeftSidebar />
            </Box>

            <Box sx={{ 
              flex: { xs: 1, lg: '0 1 1200px' },
              marginLeft: { xs: 0, lg: '240px' }
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2,
                padding: 2,
                justifyContent: { xs: 'flex-start', lg: 'center' }
              }}>
                <Box sx={{ 
                  flex: { xs: 1, md: '0 1 800px' },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Box sx={{ width: "100%", maxWidth: 800, mb: 3 }}>
                    <Stories />
                  </Box>
                  
                  <Box sx={{ 
                    width: "100%", 
                    maxWidth: 800,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    gap: 3,
                    mb: 4
                  }}>
                    <VideoCard />
                  </Box>
                  <div className="twrd"></div>

                  <Box sx={{ 
                    width: '100%',
                    maxWidth: 500,
                    display: { xs: 'flex', md: 'none' },
                    justifyContent: 'center',
                    mb: 3
                  }}>
                    <RightSidebar />
                  </Box>

                  <Box sx={{ 
                    width: "100%", 
                    maxWidth: 800,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    gap: 3
                  }}>
                    <PerformerCard />
                  </Box>
                </Box>

                <Box sx={{ 
                  width: { md: 300 },
                  flexShrink: 0,
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: { md: 'flex-start', lg: 'center' }
                }}>
                  <RightSidebar />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ 
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            bgcolor: 'background.paper',
            borderTop: 1,
            borderColor: 'divider'
          }}>
            <Footer />
          </Box>
        </div>
      </PerformerAWProvider>
    </PerformerProvider>
  );
}

export default App;
