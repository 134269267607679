import React, { useContext, useRef } from "react";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PerformerContext, filterPerformers } from "../contexts/PerformerContext";
import { PerformerAWContext } from "../contexts/PerformerAWContext";

const Stories = () => {
  const { performers, loading, selectedFilters } = useContext(PerformerContext);
  const { performers: performersAW, loading: loadingAW } = useContext(PerformerAWContext);
  const scrollRef = useRef(null);

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 200;
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 200;
    }
  };

  if (loading || loadingAW) {
    return <Typography>Loading performers...</Typography>;
  }

  // Filter performers based on selected filters
  const filteredPerformers = filterPerformers(performers, selectedFilters || {});
  const filteredPerformersAW = filterPerformers(performersAW, selectedFilters || {});

  // Interleave filtered performers
  const allPerformers = filteredPerformers.reduce((acc, performer, index) => {
    acc.push(performer);
    if (filteredPerformersAW[index]) {
      acc.push(filteredPerformersAW[index]);
    }
    return acc;
  }, []);

  // Add remaining filtered performers from performersAW if any
  if (filteredPerformersAW.length > filteredPerformers.length) {
    allPerformers.push(...filteredPerformersAW.slice(filteredPerformers.length));
  }

  return (
    <Box sx={{ position: "relative", display: "flex", alignItems: "center", width: "100%" }}>
      <IconButton
        onClick={handleScrollLeft}
        sx={{
          position: "absolute",
          left: 0,
          zIndex: 1,
          backgroundColor: "white",
          "&:hover": { backgroundColor: "lightgray" },
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>

      <Box
        ref={scrollRef}
        sx={{
          display: "flex",
          overflowX: "auto",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          width: "100%",
          padding: 3,
        }}
      >
        {allPerformers.map((performer) => (
          <Tooltip 
            key={performer.uniqueModelId}
            title={` ${performer.ethnicity } `}
          >
            <Box
              sx={{
                minWidth: 80,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "0 2px",
                cursor: "pointer",
              }}
              onClick={() => window.open(performer.chatRoomUrl, '_blank')}
            >
              <Box
                sx={{
                  width: 65,
                  height: 65,
                  background: 'linear-gradient(to right, #32CD32, #00FF00)',
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={performer.profilePictureUrl}
                  alt={performer.displayName}
                  onError={(e) => {
                    console.log('Failed to load image:', e.target.src);
                    e.target.src = 'https://via.placeholder.com/55';
                  }}
                  sx={{
                    width: 55,
                    height: 55,
                    borderRadius: "50%",
                    backgroundColor: "#f0f0f0",
                    padding: "3px",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Typography
                variant="caption"
                mt={1}
                sx={{
                  color: "black",
                  overflow: "hidden",
                  fontWeight: "bold",
                  textOverflow: "ellipsis",
                  maxWidth: "80px",
                  whiteSpace: "nowrap",
                }}
              >
                {performer.displayName}
              </Typography>
            </Box>
          </Tooltip>
        ))}
      </Box>

      <IconButton
        onClick={handleScrollRight}
        sx={{
          position: "absolute",
          right: 0,
          zIndex: 1,
          backgroundColor: "white",
          "&:hover": { backgroundColor: "lightgray" },
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default Stories;
