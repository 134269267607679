import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

// Create a context to share performer data across components
export const PerformerContext = createContext();

// Export the filter function
export const filterPerformers = (performers, filters = {}) => {
  if (!Array.isArray(performers)) return [];
  
  const safeFilters = {
    ethnicity: [],
    age: [],
    fetishes: [],
    build: [],
    searchTerm: '',
    ...filters
  };

  return performers.filter(performer => {
    // If no filters are active and no search term, show all performers
    const hasActiveFilters = Object.values(safeFilters).some(arr => 
      Array.isArray(arr) ? arr.length > 0 : false
    );
    const hasSearchTerm = safeFilters.searchTerm?.trim().length > 0;
    
    if (!hasActiveFilters && !hasSearchTerm) {
      return true;
    }

    // Search term filter - using only available fields
    if (hasSearchTerm) {
      const searchTerm = safeFilters.searchTerm.toLowerCase();
      const searchableFields = [
        performer.displayName,
        performer.ethnicity,
        performer.age?.toString(),
        ...(performer.fetishes || []),  // Already split into array in performersData mapping
      ];

      const matchesSearch = searchableFields
        .filter(field => field) // Remove null/undefined values
        .some(field => {
          if (Array.isArray(field)) {
            return field.some(item => 
              item?.toString().toLowerCase().includes(searchTerm)
            );
          }
          return field?.toString().toLowerCase().includes(searchTerm);
        });

      if (!matchesSearch) return false;
    }

    // Filter checks
    const checks = {
      ethnicity: !safeFilters.ethnicity?.length || 
        safeFilters.ethnicity.some(ethnicity => 
          performer.ethnicity?.toLowerCase() === ethnicity.toLowerCase()
        ),

      age: !safeFilters.age?.length || 
        safeFilters.age.some(ageRange => {
          const age = parseInt(performer.age);
          if (!age) return false;
          switch(ageRange) {
            case "Teen 18+": return age >= 18 && age <= 19;
            case "20s": return age >= 20 && age <= 29;
            case "30s": return age >= 30 && age <= 39;
            case "40s": return age >= 40 && age <= 49;
            case "Mature 50+": return age >= 50;
            default: return false;
          }
        }),

      fetishes: !safeFilters.fetishes?.length || 
        safeFilters.fetishes.some(fetish => {
          return performer.fetishes.some(f => 
            f?.toLowerCase() === fetish.toLowerCase()
          );
        }),
    };

    return Object.values(checks).every(result => result);
  });
};

export const PerformerProvider = ({ children }) => {
  // State management for performers and loading status
  const [performers, setPerformers] = useState([]); // All performers
  const [selectedFilters, setSelectedFilters] = useState({
    ethnicity: [],
    age: [],
    fetishes: [],
    build: []
  });
  const [loading, setLoading] = useState(true);

  // Handler for when filters change
  const handleFilterChange = (newFilters) => {
    setSelectedFilters(newFilters);
  };

  // Fetch performers data when component mounts
  useEffect(() => {
    const fetchPerformers = async () => {
      try {
        // Add timestamp to prevent caching
        const timestamp = new Date().getTime();
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/performers?t=${timestamp}`);
        //console.log('Raw API response:', response);

        // Check if response is HTML instead of JSON (error case)
        if (typeof response.data === 'string' && response.data.includes('<!DOCTYPE html>')) {
          console.warn('Received HTML instead of JSON');
          setPerformers([]);
          setLoading(false);
          return;
        }

        // Transform API data into our application's format
        const performersData = response.data.map((performer) => ({
          uniqueModelId: performer['@attributes'].Id,
          performerId: performer['@attributes'].Name,
          displayName: performer['@attributes'].Name,
          status: performer['@attributes'].StreamType === 'live' ? 'free_chat' : 'offline',
          ethnicity: performer['@attributes'].Ethnicity || 'N/A',
          fetishes: performer['@attributes'].Fetishes ? performer['@attributes'].Fetishes.split(',') : [],
          age: performer['@attributes'].Age || 'N/A',
          details: {
            willingnesses: performer['@attributes'].Fetishes ? performer['@attributes'].Fetishes.split(',') : []
          },
          profilePictureUrl: performer.Media?.Pic?.Thumb?.['@attributes']?.Src || '',
          profilePictureFullUrl: performer.Media?.Pic?.Full?.['@attributes']?.Src || '',
          chatRoomUrl: `https://instacamgirlslive.com/cam/${performer['@attributes'].Name}/?smid=${performer['@attributes'].Id}`,
        }));

        setPerformers(performersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching performers:', error);
        setPerformers([]);
        setLoading(false);
      }
    };

    fetchPerformers();
  }, []);

  return (
    <PerformerContext.Provider value={{ performers, selectedFilters, loading, handleFilterChange }}>
      {children}
    </PerformerContext.Provider>
  );
};

export default PerformerProvider;