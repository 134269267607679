import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from '@mui/material';

// Styles object containing all CSS-in-JS definitions for the chat interface
const styles = {
  // Main container for the chat window
  chatContainer: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    width: '350px',
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    zIndex: 1000,
    overflow: 'hidden',
    '@media screen and (maxWidth: 768px)': {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100vw',
      height: '100vh',
      margin: 0,
      borderRadius: 0,
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  // Header section of the chat window
  chatHeader: {
    padding: '15px',
    background: 'linear-gradient(to right, #FF7565, #FF4196)',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white'
  },
  headerInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px', // Space between image and name
  },
  profileImage: {
    width: '32px',
    height: '32px',
    borderRadius: '50%', // Makes the image round
    objectFit: 'cover', // Ensures image covers the space nicely
    border: '2px solid rgba(255, 255, 255, 0.6)', // Subtle white border
  },
  performerName: {
    margin: 0,
    fontSize: '16px',
    fontWeight: '500',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '24px',
    cursor: 'pointer',
    padding: '0 8px',
  },
  // Container for chat messages
  chatMessages: {
    flexGrow: 1,
    padding: '15px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '@media screen and (maxWidth: 768px)': {
      flex: 1,
      height: 'auto',
      paddingBottom: '80px'
    }
  },
  // Base style for individual messages
  message: {
    margin: '8px 0',
    padding: '12px 16px',
    borderRadius: '20px',
    maxWidth: '80%',
    wordBreak: 'break-word',
    fontSize: '15px',
  },
  // Style for user messages (right-aligned, blue)
  userMessage: {
    backgroundColor: '#C1FFF4',
    color: '#333',
    marginLeft: 'auto'
  },
  // Style for bot messages (left-aligned, grey)
  botMessage: {
    backgroundColor: '#f1f1f1',
    color: '#333',
    marginRight: 'auto'
  },
  // Container for the input form
  chatInputForm: {
    display: 'flex',
    padding: '15px',
    gap: '8px',
    borderTop: '1px solid #eee',
    backgroundColor: '#fff',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    '@media screen and (maxWidth: 768px)': {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '10px',
      zIndex: 1001
    }
  },
  // Style for the text input field
  chatInput: {
    flexGrow: 1,
    padding: '12px 16px',
    border: '1px solid #e0e0e0',
    borderRadius: '20px',
    fontSize: '16px',
    backgroundColor: '#f8f8f8',
    outline: 'none',
    transition: 'border-color 0.2s ease',
    '&:focus': {
      borderColor: '#FF7565',
      boxShadow: '0 0 0 1px rgba(255, 117, 101, 0.1)',
    },
    '&:hover': {
      borderColor: '#ddd',
    },
    '&::placeholder': {
      color: '#999',
    }
  },
  // Style for the send button
  sendButton: {
    padding: '8px 24px',
    background: 'linear-gradient(to right, #FF7565, #FF4196)',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    minWidth: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '40px',
    transition: 'transform 0.2s', // Smooth transition for hover effect
    '&:hover': {
      transform: 'scale(1.1)',
    }
  },
};

// Component to handle message rendering with clickable links
const ChatMessages = ({ message }) => {
  // Function to parse text and convert URLs to clickable links
  const createMarkup = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    
    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a 
            key={index} 
            href={part} 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ color: 'inherit', textDecoration: 'underline' }}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return <div>{createMarkup(message.content)}</div>;
};

// Add these SVG components at the top of your file
const HeartIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
      fill="url(#heart-gradient)"
    />
    <defs>
      <linearGradient id="heart-gradient" x1="2" y1="3" x2="22" y2="21" gradientUnits="userSpaceOnUse">
        <stop offset="0%" stopColor="#FF7565"/>
        <stop offset="100%" stopColor="#FF4196"/>
      </linearGradient>
    </defs>
  </svg>
);

const SendIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M21.426 11.095l-17-8A1 1 0 0 0 3.03 4.242l1.212 4.849L12 12l-7.758 2.909-1.212 4.849a1 1 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81z"
      fill="url(#heart-gradient)"
    />
    <defs>
      <linearGradient id="heart-gradient" x1="3" y1="3" x2="22" y2="21" gradientUnits="userSpaceOnUse">
        <stop offset="0%" stopColor="#FF7565"/>
        <stop offset="100%" stopColor="#FF4196"/>
      </linearGradient>
    </defs>
  </svg>
);

// Main ChatBot component
const ChatBot = ({ isOpen, onClose, performerName, performer }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = React.useRef(null);
  const inputRef = useRef(null);
  const isMobile = window.innerWidth <= 768;

  // Get fetishes based on performer data structure
  const getFetishes = (performer) => {
    if (performer.details?.willingnesses) {
      return performer.details.willingnesses;
    }
    return performer.fetishes || [];
  };

  // Define system prompt
  const systemPrompt = performer ? `You are ${performerName}, a webcam performer. 

Your profile details:
- Name: ${performer.displayName}
- Age: ${performer.age}
- Ethnicity: ${performer.ethnicity}
- Fetishes/Interests: ${getFetishes(performer).join(', ')}
- Room URL: ${performer.chatRoomUrl}

Key guidelines:
- Be friendly and engaging while staying in character as ${performer.displayName}
- IMPORTANT: Share your room link (${performer.chatRoomUrl}) when:
  * Users express interest in watching you
  * After building some rapport in the conversation
  * When discussing what you're doing
  * Naturally in conversation, don't wait to be asked
-  be more eager to invite them to watch
- Use your actual interests and fetishes in conversation: ${getFetishes(performer).join(', ')}
- Keep responses brief and natural
- try to push the user to watch your live show at ${performer.chatRoomUrl}
- Stay consistent with your listed age (${performer.age}) and ethnicity (${performer.ethnicity})
- Be proactive about inviting users to watch your stream` : '';

  // Initialize chat
  useEffect(() => {
    if (!performer?.chatRoomUrl) {
      return; // Exit early if no performer data
    }

    setMessages([
      {
        role: 'system',
        content: systemPrompt
      }
    ]);
    setInputMessage('');
    setIsLoading(false);
  }, [performer, performerName, systemPrompt]);

  // Auto-scroll effect
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Add this useEffect for input focus
  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isLoading]);

  // Don't render anything if chat is closed or no performer data
  if (!isOpen || !performer) return null;

  // Handle form submission and API communication
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    const userMessage = { role: 'user', content: inputMessage };
    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat-response`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          messages: [
            {
              content: systemPrompt,
              role: "system"
            },
            ...messages.filter(msg => msg.role !== 'system'),
            userMessage
          ]
        })
      });

      const data = await response.json();

      if (data.choices && data.choices[0]?.message) {
        setMessages(prev => [...prev, data.choices[0].message]);
        // Focus the input after bot responds
        inputRef.current?.focus();
      } else if (data.error) {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, {
        role: 'system',
        content: 'Sorry, there was an error processing your message.'
      }]);
    } finally {
      setIsLoading(false);
      // Also focus here in case of error
      inputRef.current?.focus();
    }
  };

  // Render chat interface
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          zIndex: 1500, // Higher than the IconButton's 1300
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            zIndex: 1400, // Higher than IconButton but lower than Dialog
          },
        },
      }}
    >
      <div style={{
        ...styles.chatContainer,
        ...(isMobile ? {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100vw',
          height: '100vh',
          margin: 0,
          borderRadius: 0,
          maxWidth: '100%',
          maxHeight: '100%'
        } : {})
      }}>
        {/* Chat header with title and close button */}
        <div style={styles.chatHeader}>
          <div style={styles.headerInfo}>
            <img 
              src={performer.profilePictureFullUrl} 
              alt=""
              style={styles.profileImage}
            />
            <h3 style={styles.performerName}>{performerName}</h3>
          </div>
          <button style={styles.closeButton} onClick={onClose}>×</button>
        </div>
        
        {/* Messages area with filtered system messages */}
        <div style={styles.chatMessages}>
          {messages
            .filter(message => message.role !== 'system')
            .map((message, index) => (
              <div
                key={index}
                style={{
                  ...styles.message,
                  ...(message.role === 'user' ? styles.userMessage : styles.botMessage)
                }}
              >
                <ChatMessages message={message} />
              </div>
            ))}
          {isLoading && (
            <div style={{...styles.message, ...styles.botMessage}}>
              Typing...
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input form for user messages */}
        <form style={styles.chatInputForm} onSubmit={handleSubmit}>
          <input
            ref={inputRef}
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Type a message..."
            style={styles.chatInput}
            disabled={isLoading}
          />
          <button 
            type={inputMessage.trim() ? "submit" : "button"}
            style={styles.actionButton}
            onClick={inputMessage.trim() ? null : () => setInputMessage(prev => prev + '❤️')}
            disabled={isLoading}
          >
            {inputMessage.trim() ? <SendIcon /> : <HeartIcon />}
          </button>
        </form>
      </div>
    </Dialog>
  );
};

export default ChatBot;

