import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

// Add blinking animation
const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const LiveDot = styled('span')({
  height: '8px',
  width: '8px',
  backgroundColor: '#4CAF50',
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: '8px',
  animation: `${blink} 1.5s ease-in-out infinite`,
});

const LiveText = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  color: '#4CAF50',
  fontWeight: 'bold',
});

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  borderRadius: '12px',
  overflow: 'hidden',
  transition: 'transform 0.2s ease-in-out',
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  maxWidth: '700px',
  '&:hover': {
    transform: 'scale(1.03)',
    '& .play-button': {
      transform: 'scale(1.2)',
    },
  },
}));

const MediaWrapper = styled(Box)({
  position: 'relative',
  '&:hover .play-button': {
    transform: 'translate(-50%, -50%) scale(1.2)',
  },
});

const CreateAccountButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF6B6B, #FF8E53, #FF2BAA)',
  borderRadius: '50px',
  color: 'white',
  padding: '8px 24px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF5252, #FF7043, #FF1A8C)',
    '& .play-icon': {
      transform: 'scale(1.2)',
    },
  },
}));

const ButtonPlayIcon = styled(PlayCircleIcon)({
  fontSize: '24px',
  transition: 'transform 0.2s ease-in-out',
});

// Define the import helper
const importAll = (r) => r.keys().map(r);

// Define videos at the component level
let videos = [];

try {
  const videoFiles = importAll(
    require.context('../assets/videos/cardVideos', false, /\.(mp4|webm)$/)
  );

  // Add the full domain path to the video URLs
  videos = videoFiles.map(videoUrl => ({
    videoUrl: window.location.origin + videoUrl
  }));

} catch (error) {
  console.error('Error importing videos:', error);
}

const VideoCard = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = React.useState(0);
  const [isMuted, setIsMuted] = React.useState(true);
  const videoRef = React.useRef(null);

  // Rotate videos every 10 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVideoIndex(prev => (prev + 1) % videos.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  // Log when video changes
  React.useEffect(() => {
  }, [currentVideoIndex]);

  const handleVolumeToggle = (e) => {
    e.stopPropagation(); // Prevent click from bubbling to parent
    setIsMuted(!isMuted);
  };

  const handleRedirect = (e) => {
    e.preventDefault();
    window.location.href = 'https://instacamgirls.net/signup/register/?AFNO=1-59918={transaction_id}';
  };

  React.useEffect(() => {
    const nextIndex = (currentVideoIndex + 1) % videos.length;
    const preloadVideo = new Image();
    preloadVideo.src = videos[nextIndex].videoUrl;
  }, [currentVideoIndex]);

  return (
    <StyledCard>
      <MediaWrapper onClick={handleRedirect} sx={{ cursor: 'pointer' }}>
        <Box
          ref={videoRef}
          component="video"
          height="400"
          width="100%"
          autoPlay={true}
          muted={isMuted}
          loop={false}
          playsInline
          preload="auto"
          key={videos[currentVideoIndex]?.videoUrl}
          src={videos[currentVideoIndex]?.videoUrl}
          sx={{
            objectFit: 'cover',
            backgroundColor: 'black',
          }}
        />
        <Box
          onClick={handleVolumeToggle}
          sx={{
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            backgroundColor: 'rgba(0,0,0,0.5)',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            color: 'white',
            zIndex: 1,
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.7)',
            }
          }}
        >
          {isMuted ? '🔇' : '🔊'}
        </Box>
      </MediaWrapper>
      <CardContent>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}>
            <LiveText>
              <LiveDot />
              <Typography variant="h6" noWrap sx={{ color: 'inherit' }}>
                Live now
              </Typography>
            </LiveText>
          </Box>
          
          <CreateAccountButton
            variant="contained"
            startIcon={<ButtonPlayIcon className="play-icon" />}
            onClick={handleRedirect}
          >
            Create Free Account
          </CreateAccountButton>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default VideoCard;
