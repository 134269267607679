import { useEffect } from 'react';

export const useGoogleAnalytics = () => {
  useEffect(() => {
    if (!window.gtag) return;

    // Send initial page view
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname + window.location.search,
      page_location: window.location.href,
      page_title: document.title
    });

    // Listen for URL changes
    const handleUrlChange = () => {
      window.gtag('event', 'page_view', {
        page_path: window.location.pathname + window.location.search,
        page_location: window.location.href,
        page_title: document.title
      });
    };

    window.addEventListener('popstate', handleUrlChange);
    return () => window.removeEventListener('popstate', handleUrlChange);
  }, []);
}; 