import React from "react";
import { Box } from "@mui/material";

const RightSidebar = () => {
  return (
    <Box
      sx={{
        color: "#aaa", // Light gray color
        lineHeight: "1.5", // Space between lines
        textAlign: "center", // Center the text
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      
      {/* Ad container with iframe */}
      <Box 
        sx={{
          width: "300px",
          height: "250px",
          //border: "1px solid red", // Temporary border to see the container
          overflow: "hidden",      // Ensure content doesn't spill out
        }}
      >
        <iframe 
          src="https://twinrdsyn.com/link.engine?z=88861&guid=43de1c11-debf-4ebd-9c00-c8feb05ae095&kw={keywords}"
          width="300"
          height="250"
          scrolling="no"
          frameBorder="0"
          title="Advertisement"
          style={{ maxWidth: "100%", maxHeight: "100%" }} // Ensure iframe doesn't exceed container
        />
      </Box>

    </Box>
  );
};

export default RightSidebar;
