import { Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <footer>
      <Typography sx={{ fontSize: "12px", paddingTop: "15px"}}>
        <Link href="#" sx={{ color: "inherit", textDecoration: "none" }}>Terms & Conditions</Link> ·{" "}
        <Link href="#" sx={{ color: "inherit", textDecoration: "none" }}>Privacy Policy</Link> ·{" "}
        <Link href="#" sx={{ color: "inherit", textDecoration: "none" }}>DMCA</Link> ·{" "}
      </Typography>
      <Typography sx={{ marginTop: 2, fontSize: "12px" }}>
        This website is not affiliated with Instagram or endorsed by and does not claim to represent or own any of the trademarks, trade names or rights associated with any of the products which are the property of their respective owners who do not own, endorse, or promote this website.
      </Typography>
    </footer>
  );
};

export default Footer;
